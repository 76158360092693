<template>
  <div class="warp">
    <van-tabs v-model:active="active" type="card" @change="initData">
      <van-tab title="新订单" name="10"></van-tab>
      <van-tab title="送水中" name="3"></van-tab>
      <van-tab title="已完成" name="5"></van-tab>
    </van-tabs>
    <div style="margin-bottom: 120px">
      <van-checkbox-group v-model="checked">
        <div v-for="(item, index) in dataList" :key="item.id" class="order-info">
          <div style="color: #666" @click="toDetail(item, index)">
            <div class="name">{{ item.receiver }}（手机：{{ item.receiverMobile }}）</div>
            <div class="time" v-if="active != 10">送水员：{{ item.deliveryUserName }}</div>
            <div class="time">用户下单时间： {{ item.payTime }}</div>
            <div class="time">预约送达时间： {{ item.estimateTime || item.userTime }}</div>
            <div class="time"  v-if="active == 10">分配人：{{ item.preDeliveryUserName || '' }}</div>
            <div class="address">{{ item.address }}</div>
            <van-card
                v-for="goodsInfo in item.orderItems" :key="goodsInfo.orderItemId"
                style="background: #f8f7f7"
                :num="goodsInfo.prodCount"
                :price="goodsInfo.price"
                :title="goodsInfo.prodName"
                :thumb="goodsInfo.pic"
            />
            <div class="checkbox-item" v-if="active == 10">
              <van-checkbox :name="item.orderId" :ref="el => checkboxRefs[index] = el" />
            </div>
          </div>
          <div style="display: flex;justify-content: right;">
            <van-button type="primary" v-if="active == 10" class="van-btn" @click="changeSender(item)">更换送水员</van-button>
  <!--          <van-button class="van-btn" type="danger" v-if="active != 10" @click="refund(item.orderNumber)">退款</van-button>-->
            <van-button class="van-btn" type="primary" v-if="active == 10" @click="getOrder(item.orderId)">接单</van-button>
          </div>
        </div>
      </van-checkbox-group>
    </div>
  </div>
  <div class="box-down" v-if="active == 10">
    <van-checkbox v-model="checkedAll" @click="checkAll">全选</van-checkbox>
<!--    <van-button type="primary" class="sure btn">一键接单</van-button>-->
    <van-button type="primary" class="sure btn" @click="changeSenderAll()">一键更换送水员</van-button>
  </div>
  <van-popup v-model:show="showPeople" position="bottom" :style="{ height: '75%' }" >
    <div class="popup-box">
      <div class="label">更换送水人</div>
      <div v-for="item in peopleList" :key="item.id" :class="peopleId == item.id ? 'changed card' : 'card'" @click.stop="peopleId = item.id; userInfo=item">
        {{ item.realName }}
        <van-icon v-if="peopleId == item.id" name="success" color="#ee0a24;"/>
      </div>
      <div class="empty-div"></div>
      <div class="btns">
        <van-button type="default" class="close" @click="showPeople = false">关闭</van-button>
        <van-button type="primary" class="sure" @click="changePeople">确认更换送水员</van-button>
      </div>
    </div>
  </van-popup>

  <!-- 接单 选择时间 -->
  <van-popup v-model:show="timeDialog.showPicker" round position="bottom">
    
    <van-picker
        :columns="timeList_label"
        @cancel="onCancelTimePicker"
        @confirm="onConfirmTimePicker"
    />
  </van-popup>
</template>

<script>
import { orderList, peopleList, orderSubmit,orderRefund } from '../../assets/api/order.js'
import {Toast,Dialog} from "vant";
import Moment from 'moment';
export default {
  data() {
    return {
      active: '10',
      loading: false,
      total: 0,
      showPeople: false,
      peopleList: [],
      dataList: [],
      orderId: '',
      userInfo: {},
      peopleId: '',
      checked: [],
      checkedAll: false,
      checkboxRefs: [],
      //  接单时间选择
      timeDialog: {
        showPicker: false,
        timeList: [],
        resolve: null,
        reject: null,
      },
    }
  },
  mounted() {
    this.initData()
    this.initPeopleData()
  },
  computed: {
    timeList_label() {
      let arr = this.timeDialog.timeList.map(it => it.label);
      return arr;
    }
  },
  methods: {
    initData() {
      let status = this.active
      if(this.active == 5) {
        status = '4,5,7'
      }
      const userId = (localStorage.getItem('userId'))
      const params = {
        deliveryUserId: userId,
        orderySource: 1,
        status: status,
        current: 1,
        size: 1000,
      }
      orderList(params).then(res => {
        this.dataList = res.data.data.records
        this.total = res.data.data.total
      })
    },
    initPeopleData() {
      peopleList().then(res => {
        if (res.data.code == 200) {
          this.peopleList = res.data.data
        }
      })
    },
    changePeople() {
      if (!this.orderId && this.checked.length == 0) return
      if (!this.orderId && this.checked.length > 0) {
        this.checked.forEach((item, key) => {
          const params = {
            orderId: item,
            deliveryMobile: this.userInfo.phone,
            deliveryUserId: this.userInfo.id,
            deliveryUserName:  this.userInfo.realName,
            preDeliveryMobile: this.userInfo.phone,
            preDeliveryUserId: this.userInfo.id,
            preDeliveryUserName:  this.userInfo.realName,
            // status: 3,
            // 1 是更换送水员  2 是接单 
            flagType: 1,
          }
          orderSubmit(params).then(res => {
            if (res.data.code == 200) {
              if (key == this.checked.length-1) {
                Toast.success('操作成功')
                this.showPeople = false
                this.$router.go(0)
              }
            }
          })
        })
      } else {
        const params = {
          orderId: this.orderId,
          deliveryMobile: this.userInfo.phone,
          deliveryUserId: this.userInfo.id,
          deliveryUserName:  this.userInfo.realName,
          preDeliveryMobile: this.userInfo.phone,
          preDeliveryUserId: this.userInfo.id,
          preDeliveryUserName:  this.userInfo.realName,
          // status: 3,
          // 1 是更换送水员  2 是接单 
          flagType: 1,
        }
        orderSubmit(params).then(res => {
          if (res.data.code == 200) {
            Toast.success('操作成功')
            this.showPeople = false
            this.$router.go(0)
          }
        })
      }
    },
    refund(orderNumber) {
      Dialog.confirm({
        title: '退款',
        message:'是否确认退款到支付账户中?',
      })
          .then(() => {
            const params = {
              orderNumber: orderNumber
            }
            orderRefund(params).then((res) => {
              if (res.data.code == 200) {
                Toast.success('退款成功');
                this.initData()
              }
            })
          })
    },
    changeSender(item) {
      this.showPeople = true
      this.peopleId = item.deliveryUserId
      this.orderId = item.orderId
    },
    changeSenderAll() {
      if (this.checked.length == 0) return
      this.showPeople = true
    },
    //  先选择时间
    selectTimeDialog () {
      this.timeDialog.showPicker = true;
      this.timeDialog.reject = null;
      this.timeDialog.resolve = null;
      return new Promise((resolve, reject) => {
        this.timeDialog.reject = reject;
        this.timeDialog.resolve = resolve;
        //
        this.timeDialog.timeList = [
          {label: `${Moment().format('YYYY-MM-DD')} 上午`, value: `${Moment().format('YYYY-MM-DD')} 12:00:00`},
          {label: `${Moment().format('YYYY-MM-DD')} 下午`, value: `${Moment().format('YYYY-MM-DD')} 18:00:00`},
          {label: `${Moment().add(1, 'days').format('YYYY-MM-DD')} 上午`, value: `${Moment().add(1, 'days').format('YYYY-MM-DD')} 12:00:00`},
          {label: `${Moment().add(1, 'days').format('YYYY-MM-DD')} 下午`, value: `${Moment().add(1, 'days').format('YYYY-MM-DD')} 18:00:00`},
          {label: `${Moment().add(2, 'days').format('YYYY-MM-DD')} 上午`, value: `${Moment().add(2, 'days').format('YYYY-MM-DD')} 12:00:00`},
          {label: `${Moment().add(2, 'days').format('YYYY-MM-DD')} 下午`, value: `${Moment().add(2, 'days').format('YYYY-MM-DD')} 18:00:00`},
        ];
      });
    },
    onConfirmTimePicker (time) {
      let _timeItem = this.timeDialog.timeList.find(it => it.label == time);
      this.timeDialog.showPicker = false;
      if (this.timeDialog.resolve) {
        if (_timeItem) {
          this.timeDialog.resolve({
            label:_timeItem.label,
            value: _timeItem.value
          });
        } else {
          this.timeDialog.resolve(null);
        }
      }
    },
    onCancelTimePicker () {
      this.timeDialog.showPicker = false;
      if (this.timeDialog.resolve) {
        this.timeDialog.resolve("");
      }
    },
    async getOrder(orderId) {
      //  先选择时间
      let time = await this.selectTimeDialog();
      console.log(time)
      if (!time || !time.value) {
        console.log('没有选择时间');
        return;
      }
      // return;
      const userId = (localStorage.getItem('userId'))
      const phone = (localStorage.getItem('phone'))
      const realName = (localStorage.getItem('realName'))
      const params = {
        orderId: orderId,
        deliveryMobile: phone,
        deliveryUserId: userId,
        deliveryUserName:  realName,
        // 时间
        estimateTime: time.value,
        estimateTimeStr: time.label,
        status: 3, //待收货
        // 1 是更换送水员  2 是接单 
        flagType: 2,
      }
      Dialog.confirm({
        title: '接单',
        message:'是否确认接受此订单?',
      })
          .then(() => {
            orderSubmit(params).then(res => {
              if (res.data.code == 200) {
                Toast.success('操作成功')
                this.$router.go(0)
              }
            })
          })
    },
    toDetail(item, index) {
      if (item.status === 10) {
        this.checkboxRefs[index] = item.orderNumber
        return
      }
      this.$router.push({
        path: '/order_detail',
        query: {
          orderNumber: item.orderNumber
        }
      })
    },
    checkAll() {
      if (this.checked.length == this.dataList.length) {
        this.checked = []
      } else {
        this.dataList.forEach(item => {
          if (this.checked.indexOf(item.orderId) < 0) {
            this.checked.push(item.orderId)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
::v-deep(.van-card__thumb) {
  width: 60px;
  height: 60px;
}
::v-deep(.van-card__content) {
  height: 60px;
  min-height: 60px;
}
.warp {
  padding: 15px 10px;

  ::v-deep(.van-tabs__nav) {
    margin: 0!important;
  }

  .order-info {
    width: calc(100% - 20px);
    color: #666;
    font-size: 12px;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
    margin-top: 15px;
    padding: 5px 10px;
    position: relative;
    background: #f7f8fabd;

    .name {
      font-size: 16px;
    }
    .name, .time, .address {
      line-height: 18px;
    }
    .time {
      margin: 4px 0;
    }
    .van-btn {
      min-width: 80px;
      border: none;
      height: 24px;
      line-height: 24px;
      margin-top: 10px;
      border-radius: 5px;
      margin-left: 10px;
    }
    .checkbox-item {
      position: absolute;
      right: 4px;
      top: 4px;
    }
  }
}
.popup-box {
  padding: 10px;
  position: relative;

  .label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .changed {
    color: #ee0a24;
  }
  .card {
    margin: 5px 0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
  }

  .btns {
    position: fixed;
    bottom: 0px;
    width: calc(100% - 20px);
    background: #fff;
    z-index: 999;
    height: 50px;

    .close {
      width: 80px;
      background: #999;
      color: #fff;
    }
    .sure {
      margin-left: 10px;
      width: calc(100% - 90px);
    }
  }
}
.box-down {
  width: calc(100% - 20px);
  position: fixed;
  bottom: 0px;
  height: 50px;
  padding: 10px;
  line-height: 50px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  .btn {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    border: none;
    color: #fff;
    border-radius: 8px;
  }
}
</style>
